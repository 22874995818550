@import "theme_variables.scss";
@import "_variables.scss";
@import "bootstrap/scss/bootstrap.scss";
@import "brand.scss";

// Brand overrides go here

#mc-header-above-full {
    background: $brand-darkgrey;
    z-index: 1021;

    .nav-item > a,
    .header-links > a { 
        color: rgb(152, 152, 152)!important;
        &:hover {
            color: $brand-green!important;
        }
    }
}

#mc-header-full {
    background-color: $brand-grey;
}

#mc-header-above,
#mc-header {
    width: 92%;
}

.container-content {
    margin-right: auto;
    margin-left: auto;
}

.footer-full {
    min-height: 400px;
    background-color: $brand-grey;

    .mc-brand-img {
        height: 38px;
    }
}

#mc-header-above-full {
    font-size: 13px!important;
}

a {
    i {
        color: $brand-green;
    }

    &:active,
    &:hover:not(.btn) {
        color: $brand-green!important;
    }
}



// Mezzanine override
a.dropdown-toggle:hover {
    background-color: unset!important;
}

.mc-top-navbar-ul {
    max-height: 45px;
    overflow-y: hidden;

    a.nav-link {
        font-size: 19px!important;
        font-weight: 700;
        height: 45px;
        color: white!important;
    }
}

#topnav-overflow-menu {
    .nav-link.dropdown-toggle {
            font-size: 19px!important;
            font-weight: 700;
            height: 45px;
            color: white!important;
    }
}

#mc-main-breadcrumbs {
    margin-top: 0.5rem !important;
}

.mc-top-navbar-ul {
    height: unset;
}

@include media-breakpoint-down(lg){
    #mc-header-full {
        margin-top: $mc-header-above-height;
    }

    .mc-brand-logo a {
        display: none;
    }

    .scroll-nav#mc-header-full {
        margin-top: 0;
    }

    #mc-main-content-full {
        margin-top: calc($mc-header-height-mobile + $mc-header-above-height)
    }

    .search-form {
        width: 100%;
    }
}

.footer-list {
    padding-left: 0;

    a {
        font-size: 1em !important;
        color: white !important;
    }
}

.mc-brand-img {
    height: 35px;
}

@include media-breakpoint-up(lg){
    #mc-header {
        .mc-brand-img {
            height: 50px;
        }
    }
}

.block-parallax-span-screen {
    &::before {
        left: 0!important;
    }
}

.container-content {
    width: 100%;
}

@include media-breakpoint-up(lg){
    .container-content {
        width: 80%;
        padding-left: 0;
        padding-right: 0m;
    }

    .block-parallax-span-screen {
        &::before {
            left: calc(-1 * (8vw) / 2)!important;
        }
    }
}


.block-imagegallery-title {
    color: black;
    text-align: start;
    background: white;
    font-size: 1rem;
    padding: 10px;
    position: relative;
    width: 100%;
}

.block-imagegallery-col {
    //box-shadow: black 0px 8px 24px;
}
