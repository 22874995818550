.checkout-billing-bump {
    padding-left: 14px;
}

.mc-subcart-title {
    font-weight: bold;
    font-size: 1.5em;
    @media (min-width: 768px) {
        font-size: 2em;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.mc-subcart-checkout-widget {
    font-size: 1em;
    @media (min-width: 768px) {
        font-size: 1.25em;
    }

    a {
        text-decoration: none;
    }
}

.mc-subcart-checkout-widget-checkout_flow_select,
.mc-subcart-checkout-widget-dispatcher_select,
.mc-subcart-checkout-widget-slot_select,
.mc-subcart-checkout-widget-slot_span {
    background-color: white;
    border-radius: 6px;
    border: 1px solid #ddd;
    max-width: 100%;
    margin-top: .2em;
    margin-bottom: .2em;
}

.mc-subcart-checkout-widget-slot_span {
    display: inline-block;
    padding: .2em;
}

.mc-subcart-checkout-widget-slot_link {
    text-decoration: none;
}

.mc-subcart-checkout-widget-loading,
.mc-subcart-checkout-widget-checkout_flow_select,
.mc-subcart-checkout-widget-dispatcher_select,
.mc-subcart-checkout-widget-slot_select {
    font-weight: bold;
}

.mc-subcart-checkout-widget-checkout_flow_select {
    & option {
        &[data-forced="true"],
        &[disabled="disabled"]{
            color: rgba(169, 68, 66, 1)!important;
        }
    }
}

.select2-container--default {
    & .select2-selection--single {
        border: 1px solid #ccc;
        & .select2-selection__arrow {
            @media (max-width: 768px) {
                height: 20px;
            }
        }
        & .select2-selection__rendered {
            color: #333;
            font-weight: bold;
            line-height: 1.9em;
            font-size: 0.8em;
            @media (max-width: 768px) {
                font-size: 0.8em;
            }
        }
        @media (max-width: 768px) {
            height: 22px;
        }
    }
}
.select2-results__option {
    font-size:0.95em;
    @media (max-width: 768px) {
        font-size: 0.8em;
    }
    &[data-forced="true"] {
        color: rgba(255,0,0,70%)!important;
    }
    &[data-selected="true"] {
        font-weight:bold!important;
    }
}
.select2-selection__rendered{
    &[data-forced="true"] {
        color: rgba(255,0,0,70%)!important;
    }
}

.cart-line-img img { max-height: 60px; max-width: 60px; }

.mc-edit_order {
    a {
        text-decoration: none;
    }
}