// Override any bootstrap or core/_variables.scss variables here.
// eg $brand-primary

// brand colors
$brand-green: #6e9e86;
$brand-grey: #212221;
$brand-darkgrey: #121112;

$brand-primary: $brand-green;

$enable-rounded: false;

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/Fira_Sans/FiraSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/Fira_Sans/FiraSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/Fira_Sans/FiraSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/Fira_Sans/FiraSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

$font-family-sans-serif:  "Fira Sans", sans-serif;
$brand-font: $font-family-sans-serif;

$mc-header-above-height: 45px;
$mc-header-height: 130px;
$mc-header-height-scroll: 90px;

$mc-header-nav-height: 0px;

